import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f99fb6d8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tile-position" }
const _hoisted_2 = { class: "tile-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SuccessTile = _resolveComponent("SuccessTile")!
  const _component_ErrorTile = _resolveComponent("ErrorTile")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_TransitionGroup, {
        name: "fadeOut",
        css: _ctx.store.getters['notification/getEnabledAnimation']
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.getters[
            'notification/getListNotifications'
          ], (notification, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
              (notification.type === 'success')
                ? (_openBlock(), _createBlock(_component_SuccessTile, {
                    key: notification.id,
                    id: notification.id,
                    text: notification.text,
                    class: _normalizeClass({ mt: index !== 0 })
                  }, null, 8, ["id", "text", "class"]))
                : (notification.type === 'error')
                  ? (_openBlock(), _createBlock(_component_ErrorTile, {
                      key: notification.id,
                      id: notification.id,
                      text: notification.text,
                      class: _normalizeClass({ mt: index !== 0 })
                    }, null, 8, ["id", "text", "class"]))
                  : _createCommentVNode("", true)
            ], 64))
          }), 256))
        ]),
        _: 1
      }, 8, ["css"])
    ])
  ]))
}
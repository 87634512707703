
import { defineComponent, watch } from "vue";
import SuccessTile from "@/components/atomics/SuccessTile.vue";
import ErrorTile from "@/components/atomics/ErrorTile.vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
export default defineComponent({
  name: "Notification",
  components: {
    SuccessTile,
    ErrorTile,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    watch(
      () => route,
      () => {
        store.commit("notification/removeAllNotification");
      },
      { deep: true }
    );

    return {
      store,
    };
  },
});
